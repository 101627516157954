import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
// import BlogsImg from "./BlogsImg";
import AddressImg from "./AddressImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";

const contactData = contactPageData.contactSection;
// const blogSection = contactPageData.blogSection;
// const addressSection = contactPageData.addressSection;
// const phoneSection = contactPageData.phoneSection;
const formSection = contactPageData.FormSection;
const emailSection = contactPageData.emailSection;

class Contact extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img
                  src={require(`../../assests/images/${contactData["profile_image_path"]}`)}
                  alt=""
                />
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {contactData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {contactData["description"]}
                </p>
                <SocialMedia theme={theme} />
                {/* <div className="resume-btn-div">
                  <Button
                    text="See My Resume"
                    newTab={true}
                    href={greeting.resumeLink}
                    theme={theme}
                  />
                </div> */}
              </div>
            </div>
          </Fade>

          <form
            action="https://formspree.io/mgenljbl	"
            method="POST"
            className="my-form"
          >
            <div className="pb-3">
              <h1
                className="address-heading-text"
                style={{ color: "rgb(20, 33, 61)" }}
              >
                {formSection.title}
              </h1>
              <h4 className="form-fill-section">
                {formSection.subTitle}
              </h4>
            </div>
            {/* <label for="name">Name</label> */}
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              required
            />

            {/* <label for="Subject">Subject</label> */}
            <input
              type="text"
              id="Subject"
              name="Subject"
              placeholder="Subject"
              required
            />

            {/* <label for="Subject">Email</label> */}
            <input
              className="form-control"
              type="email"
              name="_replyto"
              placeholder="E-mail"
              required
            />

            {/* <label for="message">Subject</label> */}
            <textarea
              className="form-control"
              name="message"
              placeholder="Your Message"
              required
            ></textarea>

            <input className="" type="submit" value="Send" />
          </form>

          <Fade bottom duration={1000} distance="40px">
            <div className="address-heading-div">
              <div className="contact-heading-img-div">
                {/* <img
											src={require(`../../assests/images/${addressSection["avatar_image_path"]}`)}
											alt=""
										/> */}
                <AddressImg theme={theme} />
              </div>
              <div className="address-heading-text-div">
                {/* <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {addressSection["title"]}a
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {addressSection["subtitle"]}
                </p> */}
                {/* <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {phoneSection["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {phoneSection["subtitle"]}
                </p> */}

                <h1
                  className="address-heading-text"
                  style={{ color: theme.text }}
                >
                  {emailSection["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {emailSection["subtitle"]}
                </p>

                {/* <div className="address-btn-div">
                  <Button
                    text="Visit on Google Maps"
                    newTab={true}
                    href={addressSection.location_map_link}
                    theme={theme}
                  />
                </div> */}
              </div>
            </div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Contact;
