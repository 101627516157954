// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Anoop Varghese | Software Developer",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Anoop Varghese | Software Developer",
    type: "website",
    url: "https://anoopvarghese.in/",
  },
};

//Home Page
const greeting = {
  title: "Anoop Varghese",
  logo_name: "Anoop Varghese",
  nickname: "< Software Developer />",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "",
  portfolio_repository: "https://github.com/vanoop729",
  githubProfile: "https://github.com/vanoop729",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/vanoop729",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/anoopv1234/",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  {
    name: "Gmail",
    link: "mailto:vanoop729@gmail.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "Twitter",
    link: "https://twitter.com/anoop_v_18",
    fontAwesomeIcon: "fa-twitter",
    backgroundColor: "#1DA1F2",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/an.o.o.p/",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using ReactJS and NextJS.",
        "⚡ Developing mobile applications using Android Studio.",
        "⚡ Creating application backend in Java and NodeJS.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#0064a5",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:coffeescript",
          style: {
            color: "#D0A384",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#563d7c",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#50C878",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms like Google and Zoho Cloud platforms.",
        "⚡ Hosting and maintaining websites along with integration of databases.",
        "⚡ Experience in deploying websites on Heroku and Netlify.",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "GIMP",
          fontAwesomeClassname: "simple-icons:gimp",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Webflow",
          fontAwesomeClassname: "simple-icons:webflow",
          style: {
            color: "#4285F4",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/anoop_varghese",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/vanoop729",
    },
    {
      siteName: "GitHub",
      iconifyClassname: "simple-icons:github",
      style: {
        color: "#000000",
      },
      profileLink: "https://www.github.com/vanoop729",
    },
    {
      siteName: "Salesforce Trialhead",
      iconifyClassname: "simple-icons:salesforce",
      style: {
        color: "#4285F4",
      },
      profileLink: "https://trailblazer.me/id/anoov",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Karunya Institute of Technology and Sciences",
      subtitle: "B.Tech. in Computer Science Engineering",
      logo_path: "kits_logo.png",
      alt_name: "KITS",
      duration: "2018 - 2022",
      descriptions: [
        "⚡ Active part of various technical organizations like IAESTE, IEEE, CodeChef chapter and KarunyaHacks.",
        "⚡ Apart from this, I have done courses on Technical Support, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for central government Merit cum Means Scholarship for consistent performance in academics.",
      ],
      website_link: "https://www.karunya.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Architecting with Google Compute Engine",
      subtitle: "- Google | Coursera",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/certificate/FDRUR6XYLWQ6",
      alt_name: "Architecting with Google Compute Engine",
      color_code: "#0C9D5899",
    },
    {
      title: "Version control with Git",
      subtitle: "-Atlassian",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/L5FD6STVKUMH",
      alt_name: "VCS",
      color_code: "#1F70C199",
    },
    {
      title: "The fundamentals of Digital Marketing",
      subtitle: "- Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1V1WqMAGnLbUCzU6_K7Ij0IQJPRQNxwnP/view",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Technical Support Fundamentals",
      subtitle: "- Google | Coursera",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/KJQJ8FJYDUVH",
      alt_name: "Technical Support Fundamentals",
      color_code: "#0C9D5899",
    },
    {
      title: "Java Certification",
      subtitle: "- Imtiaz Ahmad",
      logo_path: "udemy_logo.png",
      certificate_link:
        "https://udemy-certificate.s3.amazonaws.com/image/UC-db9c80ea-ba93-4f19-a45f-e9684484e84a.jpg",
      alt_name: "Java",
      color_code: "#1F70C199",
    },
    {
      title: "Google Cloud Essentials",
      subtitle: "- Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/139bf00e-a40d-42cd-933b-b718f7eb65a7/badges/313297",
      alt_name: "Google Cloud Essentials",
      color_code: "#0C9D5899",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Member Technical Staff",
          company: "Zoho Corporation",
          company_url: "https://www.zoho.com/",
          logo_path: "zoho_logo.png",
          duration: "May 2022 - Present",
          location: "Chennai",
          description:
            "Working as a software engineer on the Patch Manager Plus product, one of the most famous patch management solution in the industry. I have experience working on Java, MERN stack, Micro Services, and Zoho Cloud Platform.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Project Trainee",
          company: "Zoho Corporation",
          company_url: "https://www.zoho.com/",
          logo_path: "zoho_logo.png",
          duration: "Dec 2021 - Apr 2022",
          location: "Chennai",
          description: "",
          color: "#ee3c26",
        },
        {
          title: "React Developer",
          company: "Bucle",
          company_url: "https://www.bucle.in/",
          logo_path: "bucle_logo.jpg",
          duration: "Jul 2021 - Oct 2021",
          location: "Work From Home",
          description: "",
          color: "#0071C5",
        },
        {
          title: "IAESTE Remote intern",
          company: "University of Kalamoon, Syria",
          company_url: "http://www.uok.edu.sy/?lang=en#top",
          logo_path: "iaeste_logo.png",
          duration: "Jun 2021 - Jul 2021",
          location: "Work From Home",
          description: "",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Technical Experience",
      experiences: [
        {
          title: "President",
          company: "CodeChef KITS Chapter",
          company_url: "https://www.codechef.com/users/vanoop729",
          logo_path: "codechef_logo2.jpg",
          duration: "Nov 2020 - Apr 2022",
          location: "College",
          description:
            "Got an opportunity to lead Karunya CodeChef chapter to conduct various activities in and around campus to improve competitive programming culture in our college.",
          color: "#ee3c26",
        },
        {
          title: "Incharge of website and media",
          company: "IEEE Karunya Student branch",
          company_url: "https://www.karunya.edu/ieee",
          logo_path: "ieee_logo.png",
          duration: "Oct 2020 - Apr 2022",
          location: "College",
          description:
            "Got selected as Incharge of IEEE Karunya website. Responsibilities include maintaining and updating the website time to time.",
          color: "#0071C5",
        },
        {
          title: "Core Team Member",
          company: "KarunyaHacks",
          company_url: "https://www.bucle.in/",
          logo_path: "khacks_logo.png",
          duration: "Feb 2020 - Apr 2022",
          location: "College",
          description:
            "KarunyaHacks is a student community which aims to serve students in their professional pursuit of research and industry interests. I have trained students in various events and webinars.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Open Source Contributor",
          company: "DevIncept",
          company_url: "https://www.devincept.com/",
          logo_path: "devincept_logo.jpg",
          duration: "Nov 2020 - Dec 2020",
          location: "",
          description:
            "Contributed to various public repositories related to web development during the 1 month long intensive contributor program at DevIncept.",
          color: "#4285F4",
        },
        {
          title: "Student Coordinator",
          company: "Youth Red Cross",
          company_url: "https://www.indianredcross.org/youth/why.htm",
          logo_path: "yrc_logo.png",
          duration: "Jul 2019 - Jun 2020",
          location: "",
          description:
            "Took part in various activities and social services like blood donation, cleanliness and creating awareness.",
          color: "#D83B01",
        },
        {
          title: "Student Volunteer",
          company: "National Service Scheme",
          company_url: "https://nss.gov.in/",
          logo_path: "nss_logo.jpg",
          duration: "Jul 2018 - Apr 2019",
          location: "",
          description:
            "Took part in various activities and social services like disaster management, blood donation, plantation and teaching.",
          color: "#000000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to develop fully functional full stack web applications and deploy them to the cloud.",
  avatar_image_path: "projects_image.svg",
};

// const publicationsHeader = {
//   title: "",
//   description: "",
//   avatar_image_path: "",
// };

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "anoop_img.png",
    description:
      "You can connect with me via any one of the following channels. I can help you with ReactJS, NextJS, Android App Development, Full Stack and Opensource Development.",
  },
  FormSection: {
    title: "Fill a form!",
    subTitle: "Note: If you are interested in filling out a form, please provide a valid email address or another communication method.",
  },
  addressSection: {
    title: "Address",
    subtitle: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "",
  },
  emailSection: {
    title: "Email",
    subtitle: "vanoop729@gmail.com",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
